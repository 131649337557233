import { createReducer } from "@reduxjs/toolkit";
import authActions from "../../actions/auth";
import { createAsyncSlice } from "../../types";
import { type StartupStateType } from "../../types/startup";

export const initialState = {
  auth: createAsyncSlice({
    jwt: "",
    loggedIn: false,
    cookieExpireTime: 0,
    showRefreshSession: false,
    profile: null,
    redirectDestination: "",
  }),
};

const startupReducer = createReducer<StartupStateType>(
  initialState,
  (builder) => {
    builder
      .addCase(authActions.apiFetching, onApiFetching)
      .addCase(authActions.apiSuccess, onApiSuccess)
      .addCase(authActions.apiError, onApiError)
      .addCase(authActions.setLoggedIn, onSetLoggedIn)
      .addCase(authActions.sendEmailLinkSuccess, onSendEmailLinkSuccess)
      .addCase(authActions.setShowRefreshMsg, onSetShowRefreshSession)
      .addCase(authActions.setCookieUpdate, onSetCookieUpdate)
      .addCase(authActions.authorizeToken, onAuthorizeToken)
      .addCase(authActions.setAuthToken, onSetAuthToken)
      .addCase(authActions.logout, onLogout)
      .addCase(authActions.setMerchantProfile, onSetMerchantProfile)
      .addCase(authActions.redirect, onRedirect)
      .addCase(
        authActions.clearRedirectDestination,
        onClearRedirectDestination,
      );
  },
);
export default startupReducer;

function onApiFetching(
  state: StartupStateType,
  action: ReturnType<typeof authActions.apiFetching>,
) {
  state.auth.fetching = action.payload.fetching;
}

function onApiError(
  state: StartupStateType,
  action: ReturnType<typeof authActions.apiError>,
) {
  state.auth.error = action.payload.error;
}

function onApiSuccess(
  state: StartupStateType,
  action: ReturnType<typeof authActions.apiSuccess>,
) {
  state.auth.success = action.payload.success;
}

function onSetLoggedIn(
  state: StartupStateType,
  action: ReturnType<typeof authActions.setLoggedIn>,
) {
  if (action.payload.loggedIn) {
    state.auth.data.loggedIn = action.payload.loggedIn;
  } else {
    state.auth.data.loggedIn = false;
  }
}

function onSendEmailLinkSuccess(state: StartupStateType) {
  state.auth.fetching = false;
  state.auth.error = { message: "", status: 0 };
}

function onSetShowRefreshSession(
  state: StartupStateType,
  action: ReturnType<typeof authActions.setShowRefreshMsg>,
) {
  state.auth.data.showRefreshSession = action.payload.show;
}

function onSetCookieUpdate(
  state: StartupStateType,
  action: ReturnType<typeof authActions.setCookieUpdate>,
) {
  if (action.payload.timestamp) {
    state.auth.data.cookieExpireTime = action.payload.timestamp;
  }
}

function onAuthorizeToken(
  state: StartupStateType,
  action: ReturnType<typeof authActions.authorizeToken>,
) {
  state.auth.data.jwt = action.payload.token;
}

// While this action does the same setting of the auth token as actions.authorizeToken,
// this action is not included in the saga to check an auth token against the
// backend. Instead it simply sets the authToken on the frontend.
function onSetAuthToken(
  state: StartupStateType,
  action: ReturnType<typeof authActions.setAuthToken>,
) {
  state.auth.data.jwt = action.payload.token;
}

function onLogout(state: StartupStateType) {
  state.auth.data.loggedIn = false;
  state.auth.data.jwt = "";
  state.auth.data.cookieExpireTime = 0;
  state.auth.data.showRefreshSession = false;
}

function onSetMerchantProfile(
  state: StartupStateType,
  action: ReturnType<typeof authActions.setMerchantProfile>,
) {
  state.auth.data.profile = action.payload.profile;
}

function onRedirect(
  state: StartupStateType,
  action: ReturnType<typeof authActions.redirect>,
) {
  state.auth.data.redirectDestination = action.payload.destination;
}

function onClearRedirectDestination(state: StartupStateType) {
  state.auth.data.redirectDestination = "";
}
