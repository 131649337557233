import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import AuthGuard from "@pd/components/AuthGuard";
import { useLocation, useNavigate } from "react-router";
import { FadeInStack } from "@pd/components/FadeComponents";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import formatCurrency from "@pd/utils/formatCurrency";
import OrdersTable from "./components/OrdersTable";
import CreateEditOrder from "./components/CreateEditOrder";
import OrderDetails from "./components/OrderDetails";
import {
  selectCreditLimitFetching,
  selectCreditLimitInfo,
  selectCreditLimitSuccess,
} from "../../redux/selectors/credit";

export default function Orders() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();

  const [showCreateEditOrder, setShowCreateEditOrder] = useState(false);
  const [orderId, setTargetOrderId] = useState("");
  const id = params.get("id");

  const isCreditLimitFetching = useAppSelector(selectCreditLimitFetching);
  const isCreditLimitSuccess = useAppSelector(selectCreditLimitSuccess);
  const creditLimitInfo = useAppSelector(selectCreditLimitInfo);

  useEffect(() => {
    if (id) {
      setTargetOrderId(id as string);
    }
  }, [id]);

  useEffect(() => {
    dispatch(da.credit.limit.fetchCreditLimitInfo());
  }, []);

  const renderCreditLimit = () => {
    if (!isCreditLimitFetching && isCreditLimitSuccess) {
      return (
        <>
          <Typography variant="h3">Credit usage</Typography>
          <Typography>
            {formatCurrency(creditLimitInfo.data.consumedCredit || 0)} /{" "}
            {formatCurrency(creditLimitInfo.data.creditLimit || 0)}
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography variant="h3">Credit usage</Typography>
        <Typography>...</Typography>
      </>
    );
  };

  const renderSubview = () => {
    if (showCreateEditOrder) {
      return (
        <CreateEditOrder
          orderId={orderId}
          onCloseForm={() => {
            navigate("/dashboard/orders");
            setShowCreateEditOrder(false);
            setTargetOrderId("");
          }}
        />
      );
    }
    if (orderId) {
      return (
        <OrderDetails
          orderId={orderId}
          onCloseForm={() => {
            navigate("/dashboard/orders");
            setTargetOrderId("");
          }}
        />
      );
    }
    return (
      <>
        <Stack
          direction={{
            lg: "row",
            xs: "column",
          }}
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ mt: "30px", mb: "60px", width: "100%" }}
          spacing={2}
        >
          {renderCreditLimit()}
          <Typography
            variant="h1"
            sx={{ fontWeight: 500, fontFamily: "CircularBold" }}
          >
            Orders
          </Typography>
        </Stack>
        <Stack sx={{ width: "100%" }} data-testid="transfers-table">
          <OrdersTable
            onShowCreateOrder={(id) => {
              setShowCreateEditOrder(true);
              if (id) setTargetOrderId(id);
            }}
            onSetOrderId={setTargetOrderId}
          />
        </Stack>
      </>
    );
  };

  return (
    <AuthGuard>
      <FadeInStack
        alignItems="flex-start"
        sx={{
          pt: "80px",
          px: "50px",
          width: "100%",
          height: "100%",
        }}
      >
        {renderSubview()}
      </FadeInStack>
    </AuthGuard>
  );
}
