import { Button } from "@mui/material";
import { Fade, Modal, Stack, Typography } from "@mui/material";
import ModalTitle from "@pd/components/ModalTitle";
import StitchLoading from "@pd/components/StitchLoading";
import { selectAllSessionsApiFetching } from "@pd/layouts/MktplaceDashboard/redux/selectors/sessions";
import { useAppSelector } from "@pd/redux/store";
import { useCallback } from "react";

type Props = {
  onClose: () => void;
  onConfirmLogout: () => void;
};

export default function ConfirmLogoutModal(props: Props) {
  const modalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    minWidth: "520px",
    minHeight: "360px",
  };

  const apiFetching = useAppSelector(selectAllSessionsApiFetching);

  const handleOnClose = useCallback(() => {
    props.onClose();
  }, []);

  return (
    <Modal open>
      <Fade in>
        <Stack
          gap={2}
          sx={{
            ...modalStyles,
            height: "360px",
          }}
          justifyContent="flex-start"
        >
          <ModalTitle title="Sign out everywhere?" onClose={handleOnClose} />
          <Stack
            gap={3}
            alignItems="center"
            justifyContent="center"
            sx={{ my: "auto", p: 3 }}
          >
            {(() => {
              if (apiFetching) {
                return <StitchLoading />;
              }
              return (
                <Stack
                  direction="column"
                  gap={7}
                  justifyContent="flex-end"
                  style={{ height: "100%" }}
                >
                  <Typography>
                    This will cause all devices to sign out, including this one.
                    <br />
                    <br />
                    You will need to log in again to use the dashboard.
                  </Typography>
                  <Stack direction="row" spacing={3}>
                    <Button
                      variant="outlined"
                      onClick={props.onClose}
                      sx={{ width: "200px", height: "40px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={props.onConfirmLogout}
                      sx={{ width: "200px", height: "40px" }}
                    >
                      Sign out
                    </Button>
                  </Stack>
                </Stack>
              );
            })()}
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
}
