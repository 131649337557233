import { createSelector } from "@reduxjs/toolkit";
import { AppStateType } from "@pd/redux/store/reducers";
import { SessionDbType } from "@pd/redux/types/dbTypes";
import { isDashboardState } from "@pd/layouts/MktplaceDashboard/types";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

export const selectAllSessions = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): SessionDbType[] => {
    if (isDashboardState(state)) {
      const data = state.dashboard.sessions.allSessions.data.sessions;
      if (data) {
        return data;
      }
    }
    return [];
  },
);

export const selectAllSessionsApiFetching = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.sessions.allSessions.fetching;
    }
    return false;
  },
);

export const selectAllSessionsApiSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.sessions.allSessions.success;
    }
    return false;
  },
);

export const selectAllSessionsApiError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): ErrorPayload => {
    if (isDashboardState(state)) {
      return state.dashboard.sessions.allSessions.error;
    }
    return { message: "", status: 0 };
  },
);
