import StitchLoading from "@pd/components/StitchLoading";
// import { useAppDispatch, useAppSelector } from "@pd/redux/store";

import MainCard from "@pd/components/MainCard";
// import { isProdEnv, isSandboxEnv, isStagingEnv } from "@pd/utils/envCheck";
// import LabeledKeyRow from "./components/LabeledKeyRow";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import { useEffect, useMemo, useState } from "react";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@pd/redux/store";
import {
  selectAllSessions,
  selectAllSessionsApiFetching,
} from "@pd/layouts/MktplaceDashboard/redux/selectors/sessions";
import SessionRow from "./components/SessionRow";
import ConfirmLogoutModal from "./components/ConfirmLogoutModal";

export default function ManageSessions() {
  const dispatch = useDispatch();

  const apiFetching = useAppSelector(selectAllSessionsApiFetching);
  const allSessions = useAppSelector(selectAllSessions);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    dispatch(da.sessions.all.fetchAllSessions());
  }, []);

  const onDeleteSession = (publicId: string) => {
    console.warn("delete session: ", publicId);
  };

  const onClickSignOutAll = () => {
    setShowConfirmModal(true);
  };

  const onConfirmSignOutAll = () => {
    setShowConfirmModal(false);
    dispatch(da.sessions.all.deleteAllSessions());
  };

  const linkedAcctsHeader = useMemo(
    () => (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" sx={{ fontFamily: "CircularBold" }}>
          Manage logins
        </Typography>
        <Stack direction="row" gap={2}>
          <Button
            variant="contained"
            color="error"
            sx={{
              fontSize: "16px",
            }}
            onClick={onClickSignOutAll}
          >
            Sign out of all devices
          </Button>
        </Stack>
      </Stack>
    ),
    [apiFetching],
  );

  if (apiFetching) {
    return (
      <MainCard
        darkTitle
        title={linkedAcctsHeader}
        sx={{
          mb: 5,
          width: "100%",
          minHeight: "200px",
        }}
      >
        <Stack
          sx={{ width: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          <StitchLoading size="medium" />
        </Stack>
      </MainCard>
    );
  }
  return (
    <MainCard
      darkTitle
      title={linkedAcctsHeader}
      sx={{
        mb: 5,
        width: "100%",
        minHeight: "200px",
      }}
    >
      {showConfirmModal ? (
        <ConfirmLogoutModal
          onClose={() => setShowConfirmModal(false)}
          onConfirmLogout={onConfirmSignOutAll}
        />
      ) : null}
      <Stack
        divider={<Divider orientation="horizontal" flexItem />}
        alignItems="center"
        style={{
          marginTop: "-20px",
        }}
      >
        {allSessions.map((s, _) => (
          <SessionRow
            key={s.publicId}
            session={s}
            onDelete={() => onDeleteSession(s.publicId)}
          />
        ))}
      </Stack>
    </MainCard>
  );
}
