import { takeLeading, all, put, call, delay } from "redux-saga/effects";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import {
  fetchAllSessions,
  AllSessionsResType,
  deleteAllSessions,
} from "@pd/api/dashboard/sessions";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import authActions from "@pd/redux/actions/auth";
import { clearAllCookies } from "@pd/utils/clearAllCookies";

export default function* allSessionsSaga() {
  yield all([
    takeLeading(da.sessions.all.fetchAllSessions, onFetchAllSessions),
    takeLeading(da.sessions.all.deleteAllSessions, onDeleteAllSessions),
  ]);
}

function* onFetchAllSessions() {
  try {
    yield all([
      put(da.sessions.all.apiFetching(true)),
      put(da.sessions.all.apiError({ message: "", status: 0 })),
    ]);
    const res: AllSessionsResType = yield call(fetchAllSessions);
    if ("error" in res) {
      yield all([
        put(da.sessions.all.apiFetching(false)),
        put(da.sessions.all.apiSuccess(false)),
        put(da.sessions.all.apiError(res.error)),
      ]);
    } else {
      yield delay(400);
      yield all([
        put(da.sessions.all.setAllSessions(res.data)),
        put(da.sessions.all.apiSuccess(true)),
        put(da.sessions.all.apiFetching(false)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while fetching your Sessions. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.sessions.all.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.sessions.all.apiFetching(false)),
      put(da.sessions.all.apiSuccess(false)),
    ]);
  }
}

function* onDeleteAllSessions() {
  try {
    yield all([
      put(da.sessions.all.apiFetching(true)),
      put(da.sessions.all.apiError({ message: "", status: 0 })),
    ]);
    const res: AllSessionsResType = yield call(deleteAllSessions);
    if ("error" in res) {
      yield all([
        put(da.sessions.all.apiFetching(false)),
        put(da.sessions.all.apiSuccess(false)),
        put(da.sessions.all.apiError(res.error)),
      ]);
    } else {
      yield all([
        put(da.sessions.all.setAllSessions([])),
        put(da.sessions.all.apiSuccess(true)),
        put(da.sessions.all.apiFetching(false)),
        put(authActions.setLoggedIn(false)),
        clearAllCookies(),
      ]);
      yield put(da.routes.redirect("/"));
    }
  } catch (error) {
    const errMsg =
      "An error occured while fetching your Sessions. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.sessions.all.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.sessions.all.apiFetching(false)),
      put(da.sessions.all.apiSuccess(false)),
    ]);
  }
}
