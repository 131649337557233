import { useState, useCallback, useEffect } from "react";
import { Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import {
  selectAuthApiFetching,
  selectAuthApiError,
  selectAuthApiSuccess,
} from "@pd/redux/selectors/auth";
import authActions from "@pd/redux/actions/auth";
import EmailLoginForm from "./components/EmailLoginForm";
import EmailSentForm from "./components/EmailSentForm";

export default function AuthEmailLogin() {
  const dispatch = useAppDispatch();
  const apiSuccess = useAppSelector(selectAuthApiSuccess);
  const apiFetching = useAppSelector(selectAuthApiFetching);
  const apiError = useAppSelector(selectAuthApiError);
  const [showEmailSent, setShowEmailSent] = useState(false);
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [uiFetching, setUiFetching] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (apiSuccess && hasSentEmail) {
      dispatch(authActions.apiSuccess(false));
      setShowEmailSent(true);
    }
  }, [apiSuccess, hasSentEmail]);

  const apiErrMsg = apiError.message;
  useEffect(() => {
    if (!apiFetching && apiErrMsg && uiFetching) {
      setUiFetching(false);
    } else if (!apiFetching && uiFetching) {
      setShowEmailSent(true);
      setUiFetching(false);
    }
  }, [apiErrMsg, apiFetching, uiFetching]);

  const handleOnSendEmail = useCallback(() => {
    dispatch(authActions.sendEmailLink(email.trim()));
    setUiFetching(true);
    setEmail("");
    setHasSentEmail(true);
  }, [email]);

  const handleOnEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setEmail(e.target.value);
    },
    [],
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
        handleOnSendEmail();
      }
    },
    [handleOnSendEmail],
  );

  return (
    <Stack
      sx={{ height: "100%", maxWidth: "488px" }}
      alignItems="center"
      justifyContent="center"
    >
      {showEmailSent ? (
        <EmailSentForm onSetShowEmailSent={setShowEmailSent} />
      ) : (
        <EmailLoginForm
          email={email}
          errorMsg={apiError.message}
          fetching={apiFetching}
          success={showEmailSent}
          onEmailChange={handleOnEmailChange}
          onSendEmail={handleOnSendEmail}
          onKeyDown={handleOnKeyDown}
        />
      )}
    </Stack>
  );
}
