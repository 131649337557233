export const isMobile = (userAgent: string) =>
  /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent,
  );

export const isTablet = (userAgent: string) => {
  const ua = userAgent.toLowerCase();

  // Check for common tablet identifiers
  if (
    ua.includes("ipad") ||
    (ua.includes("android") &&
      ua.includes("mobile") &&
      ua.includes("tablet")) ||
    ua.includes("silk") ||
    ua.includes("playbook") ||
    ua.includes("kindle") ||
    ua.includes("gt-p") || // Samsung Galaxy Tab
    ua.includes("sm-t") || // Samsung Galaxy Tab
    ua.includes("nexus") // Nexus tablets
  ) {
    return true;
  }

  return false;
};

export const isWindowsPhone = (userAgent: string) => {
  if (/windows phone/i.test(userAgent)) {
    return true;
  }
  return false;
};

export const isAndroid = (userAgent: string) => {
  if (/android/i.test(userAgent)) {
    return true;
  }
  return false;
};

export const isIOS = (userAgent: string) => {
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return true;
  }
  return false;
};
