import { createSelector } from "@reduxjs/toolkit";
import { AppStateType } from "@pd/redux/store/reducers";
import { ErrorPayload } from "@pd/api/utils/safeFetch";
import {
  CreditAppRequestorFormType,
  CreditLimitStateType,
} from "@pd/layouts/MktplaceDashboard/types/credit";
import { CreditAppSectionDbType } from "@pd/redux/types/dbTypes";
import { isDashboardState } from "@pd/layouts/MktplaceDashboard/types";

export const selectCreditAppFetching = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.credit.app.fetching;
    }
    return false;
  },
);

export const selectCreditAppError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): ErrorPayload => {
    if (isDashboardState(state)) {
      return state.dashboard.credit.app.error;
    }
    return {
      message: "",
      status: 0,
    };
  },
);

export const selectCreditAppSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.credit.app.success;
    }
    return false;
  },
);

export const selectUiApp = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): CreditAppRequestorFormType | null => {
    if (isDashboardState(state)) {
      return state.dashboard.credit.app.data.ui;
    }
    return null;
  },
);

export const selectApiApp = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): CreditAppSectionDbType | null => {
    if (isDashboardState(state)) {
      return state.dashboard.credit.app.data.api;
    }
    return null;
  },
);

export const selectCreditLimitFetching = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.credit.creditLimitInfo.fetching;
    }
    return false;
  },
);

export const selectCreditLimitError = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): ErrorPayload => {
    if (isDashboardState(state)) {
      return state.dashboard.credit.creditLimitInfo.error;
    }
    return {
      message: "",
      status: 0,
    };
  },
);

export const selectCreditLimitSuccess = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): boolean => {
    if (isDashboardState(state)) {
      return state.dashboard.credit.creditLimitInfo.success;
    }
    return false;
  },
);

export const selectCreditLimitInfo = createSelector(
  (state: AppStateType) => state,
  (state: AppStateType): CreditLimitStateType => {
    if (isDashboardState(state)) {
      return state.dashboard.credit.creditLimitInfo;
    }
    return {
      data: {
        consumedCredit: null,
        creditLimit: null,
      },
      success: false,
      fetching: false,
      error: {
        message: "",
        status: 0,
      },
    };
  },
);
