import { combineReducers } from "@reduxjs/toolkit";

import { DashboardStateType } from "../../types";
import createPingPongReducer from "./pingPong";
import createAllBuyersReducer from "./buyers/allBuyers";
import createCrudBuyerReducer from "./buyers/crudBuyer";
import createAllOrdersReducer from "./orders/allOrders";
import createCrudOrderReducer from "./orders/crudOrder";
import createCsvUploadReducer from "./orders/csvUpload";
import createTransfersReducer from "./transfers";
import createAllVendorsReducer from "./vendors/allVendors";
import createCrudVendorsReducer from "./vendors/crudVendors";
import createTableReducer from "./table";
import createRoutesReducer from "./routes";
import createSettingsReducer from "./settings";
import createAllTeamsReducer from "./teams/allTeams";
import createCrudTeamsReducer from "./teams/crudTeam";
import {
  createCreditApplxnReducer,
  createCreditLimitInfoReducer,
} from "./credit";
import createAllSessionsReducer from "./sessions/allSessions";

const dashboardReducer = combineReducers<DashboardStateType>({
  pingPong: createPingPongReducer(),
  buyers: combineReducers({
    allBuyers: createAllBuyersReducer(),
    crudBuyer: createCrudBuyerReducer(),
  }),
  credit: combineReducers({
    app: createCreditApplxnReducer(),
    creditLimitInfo: createCreditLimitInfoReducer(),
  }),
  transfers: createTransfersReducer(),
  table: createTableReducer(),
  routes: createRoutesReducer(),
  orders: combineReducers({
    allOrders: createAllOrdersReducer(),
    crudOrder: createCrudOrderReducer(),
    csvUpload: createCsvUploadReducer(),
  }),
  vendors: combineReducers({
    all: createAllVendorsReducer(),
    crud: createCrudVendorsReducer(),
  }),
  teams: combineReducers({
    all: createAllTeamsReducer(),
    crud: createCrudTeamsReducer(),
  }),
  sessions: combineReducers({
    allSessions: createAllSessionsReducer(),
  }),
  settings: createSettingsReducer(),
});
export default dashboardReducer;
