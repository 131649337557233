import Cookies from "js-cookie";
import {
  all,
  put,
  call,
  spawn,
  delay,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import {
  fetchAuthorizeToken,
  fetchSendMagicLink,
  fetchLogout,
  fetchRefreshSession,
  fetchSubmitPhoneCode,
  SubmitPhoneCodeResType,
  LogoutResType,
} from "@pd/api/auth";
import {
  GetMerchantProfileResType,
  fetchGetMerchantProfile,
} from "@pd/api/dashboard/profile";
import type { AuthorizeResType, RefreshSessionResType } from "@pd/api/auth";
import { getCookieName } from "@pd/utils/appCheck";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";
import { clearAllSessionCookies } from "@pd/utils/clearAllCookies";
import tracer from "@pd/tracing";
import {
  selectLoggedIn,
  selectCookieExpireTime,
  selectShowRefreshSession,
  selectJwt,
} from "../selectors/auth";
import authActions from "../actions/auth";

const cookieName = getCookieName();

export default function* startupSagas() {
  yield all([
    spawn(watchCookieExpireTime),
    spawn(watchCookieForAuthUpdate),
    takeEvery(authActions.logout.toString(), handleLogout),
    takeLeading(authActions.authorizeToken.toString(), handleAuthorizeToken),
    takeLeading(authActions.sendEmailLink.toString(), handleSendEmailLink),
    takeLeading(authActions.refreshSession.toString(), handleRefreshSession),
    takeLeading(authActions.resendPhoneCode, onResendPhoneCode),
    takeLeading(authActions.submitPhoneCode, onSubmitPhoneCode),
    takeLatest(authActions.setLoggedIn.toString(), onGetMerchantProfile),
  ]);
}

function* handleLogout() {
  const res: LogoutResType = yield call(fetchLogout);
  if ("error" in res) {
    console.error(res.error);
  } else {
    console.info("Logged out @ ", new Date().toISOString()); // eslint-disable-line no-restricted-syntax
  }
  put(authActions.setLoggedIn(false));
  put(authActions.setAuthToken(""));
  clearAllSessionCookies();
}

function* handleAuthorizeToken(
  action: ReturnType<typeof authActions.authorizeToken>,
) {
  try {
    yield all([
      put(authActions.apiFetching(true)),
      put(authActions.apiError({ message: "", status: 0 })),
    ]);
    const response: AuthorizeResType = yield call(
      fetchAuthorizeToken,
      action.payload.token,
    );
    if ("error" in response) {
      yield all([
        put(authActions.setLoggedIn(false)),
        put(authActions.apiError(response.error)),
        put(authActions.apiFetching(false)),
      ]);
    } else {
      yield spawn(checkCookieForAuthUpdateOnce);
      if (typeof response.data !== "string") {
        yield all([
          put(authActions.apiError({ message: "", status: 0 })),
          put(authActions.apiFetching(false)),
          put(authActions.redirect(response.data.next)),
        ]);
      } else {
        yield all([
          put(authActions.apiError({ message: "", status: 0 })),
          put(authActions.apiFetching(false)),
          put(authActions.redirect("/auth/login/phone")),
        ]);
      }
    }
  } catch (error) {
    yield put(
      authActions.apiError({
        message:
          "There was an error while authorizing your login. Please try again.",
        status: MANUAL_ERROR_CODE,
      }),
    );
  }
}

function* handleSendEmailLink(
  action: ReturnType<typeof authActions.sendEmailLink>,
) {
  try {
    yield all([
      put(authActions.apiFetching(true)),
      put(authActions.apiError({ message: "", status: 0 })),
    ]);
    const response: AuthorizeResType = yield call(
      fetchSendMagicLink,
      action.payload.email,
    );
    if ("error" in response) {
      yield all([
        put(authActions.apiError(response.error)),
        put(authActions.setLoggedIn(false)),
      ]);
    } else {
      yield all([
        put(authActions.apiSuccess(true)),
        put(authActions.apiFetching(false)),
      ]);
    }
    yield put(authActions.apiFetching(false));
  } catch (error) {
    yield put(
      authActions.apiError({
        message:
          "There was an error while sending a link to your email. Please try again.",
        status: MANUAL_ERROR_CODE,
      }),
    );
  }
}

/**
 * This saga is called when the user clicks the refresh session button.
 * It will make a request to the server to refresh the session by passing
 * in the current cookie. If the server responds with a new cookie, the
 * cookie expiration time will be updated in state.
 * @returns {Generator}
 */
function* handleRefreshSession() {
  try {
    const oldCookieExpTime: number = yield select(selectCookieExpireTime);
    yield put(authActions.apiFetching(true));
    const response: RefreshSessionResType = yield call(fetchRefreshSession);
    if ("error" in response) {
      yield put(
        authActions.apiError({
          message: "Could not refresh session",
          status: MANUAL_ERROR_CODE,
        }),
      );
    } else {
      yield delay(1000); // Wait for new cookie to be baked into the browser.
      const newCookieExpTime: number = yield call(getCookieExpireTime);
      const replacedCookie = oldCookieExpTime !== newCookieExpTime;
      if (replacedCookie && newCookieExpTime) {
        yield all([
          put(authActions.setCookieUpdate(newCookieExpTime)),
          put(authActions.setShowRefreshMsg(false)),
        ]);
      } else {
        yield put(
          authActions.apiError({
            message: "Could not refresh session: New Cookie not found.",
            status: MANUAL_ERROR_CODE,
          }),
        );
      }
    }
    yield put(authActions.apiFetching(false));
  } catch (error) {
    yield put(
      authActions.apiError({
        message:
          "There was an error while refreshing your session. Please try again.",
        status: MANUAL_ERROR_CODE,
      }),
    );
  }
}

/**
 * This saga watches the cookie for changes and updates the state accordingly.
 * If the cookie exists in the browser but not in state, it will set the user as logged in.
 * If the cookie does not exist in the browser but is in state, it will set the user as logged out.
 * @returns {Generator}
 */
function* watchCookieForAuthUpdate() {
  while (true) {
    const loggedIn: boolean = yield select(selectLoggedIn);
    const cookie = Cookies.get(cookieName);
    if (!loggedIn) {
      if (cookie) {
        yield put(authActions.setLoggedIn(true));
      }
    } else if (!cookie) {
      yield put(authActions.setLoggedIn(false));
      console.error("Cookie expired, logging out"); // TODO: Show message to user.
    }
    yield delay(1000);
  }
}

function* checkCookieForAuthUpdateOnce() {
  const loggedIn: boolean = yield select(selectLoggedIn);
  const cookie = Cookies.get(cookieName);
  if (!loggedIn) {
    if (cookie) {
      yield put(authActions.setLoggedIn(true));
    }
  } else if (!cookie) {
    yield put(authActions.logout());
    console.error("Cookie expired, logging out");
  }
}

/**
 * This saga watches the cookie expiration time and shows the refresh session modal
 * when the cookie is 1 minute from expiring.
 * Since it runs every second, it will also update the cookie expiration time in state
 * if it chananges. The expire time could automatically update if the user makes
 * a request during the expiration window (5 minutes), but before the refresh session
 * modal is shown.
 * @returns {Generator}
 */
function* watchCookieExpireTime() {
  while (true) {
    const prevCookieExpireTime: number = yield select(selectCookieExpireTime);
    const showRefreshSession: boolean = yield select(selectShowRefreshSession);
    const cookieExpireTime: number = yield call(getCookieExpireTime);

    const noRefreshSessionInProgress = !showRefreshSession && cookieExpireTime;
    const stateCookieStale = prevCookieExpireTime !== cookieExpireTime;

    const hasBrandNewSesssion = !prevCookieExpireTime && cookieExpireTime;
    const hasRefreshedCookie = stateCookieStale && noRefreshSessionInProgress;
    const TIME_TO_EXPIRE = cookieExpireTime - Date.now();

    if (hasBrandNewSesssion || hasRefreshedCookie) {
      yield put(authActions.setCookieUpdate(cookieExpireTime));
    } else if (noRefreshSessionInProgress) {
      const ONE_MINUTE = 1 * 60 * 1000;

      if (TIME_TO_EXPIRE <= ONE_MINUTE) {
        yield put(authActions.setShowRefreshMsg(true));
      }
    }
    if (showRefreshSession && TIME_TO_EXPIRE <= 0) {
      yield put(authActions.setShowRefreshMsg(false));
      yield put(authActions.logout());
    }
    const ONE_SECOND = 1000;
    yield delay(ONE_SECOND);
  }
}

declare global {
  interface Window {
    cookieStore: {
      get: (name: string) => Promise<{ expires: number }>;
    };
  }
}
/**
 * Pulls the cookie from the browser and returns the expiration time.
 * @returns {Promise<number>}
 */
async function getCookieExpireTime() {
  const expireTime = document.cookie
    .split(";")
    .map((c) => c.trim().split("="))
    .reduce((acc, [key, value]) => {
      if (key === cookieName) {
        try {
          return new Date(parseInt(value, 10)).getTime() || 0;
        } catch (error) {
          console.error("Error parsing cookie expiration time.: ", error);
          return 0;
        }
      }
      return acc;
    }, 0);
  return expireTime || 0;
}

export function* onSubmitPhoneCode(
  action: ReturnType<typeof authActions.submitPhoneCode>,
) {
  try {
    yield all([
      put(authActions.apiFetching(true)),
      put(authActions.apiError({ message: "", status: 0 })),
    ]);
    const jwt: string = yield select(selectJwt);
    const res: SubmitPhoneCodeResType = yield call(
      fetchSubmitPhoneCode,
      action.payload.phoneCode,
      jwt,
      action.payload.trustThisDevice,
    );
    if ("error" in res) {
      tracer.warn(
        "User failed to submit correct phone code",
        tracer.ids.domain.SAGAS.auth,
      );
      yield all([
        put(authActions.apiError(res.error)),
        put(authActions.apiFetching(false)),
      ]);
    } else if (res.data.next === "/login") {
      yield all([
        put(authActions.apiSuccess(false)),
        put(
          authActions.apiError({
            message:
              "The code provided is invalid or has expired. Please try again.",
            status: MANUAL_ERROR_CODE,
          }),
        ),
        put(authActions.apiFetching(false)),
      ]);
    } else {
      const cookie = Cookies.get(cookieName);
      if (!cookie) {
        tracer.info(
          "Expected Cookie not found in browser after successful OTP verification.",
          tracer.ids.domain.SAGAS.auth,
          { cookie: cookie || "no-cookie" },
        );
        const msg = "Cookie not found in browser. Please try again.";
        console.error(new Error(msg));
        yield put(authActions.setLoggedIn(false));
        yield put(
          authActions.apiError({ message: msg, status: MANUAL_ERROR_CODE }),
        );
      } else {
        tracer.info(
          "User successfully logged in.",
          tracer.ids.domain.SAGAS.auth,
          { cookie: cookie || "no-cookie" },
        );
        const newCookieExpireTime: number = yield call(getCookieExpireTime);
        yield all([
          put(authActions.apiError({ message: "", status: 0 })),
          put(authActions.setLoggedIn(true)),
          put(authActions.setCookieUpdate(newCookieExpireTime)),
        ]);
      }
      yield delay(400);
      yield all([
        put(authActions.apiError({ message: "", status: 0 })),
        put(authActions.apiFetching(false)),
        put(authActions.apiSuccess(true)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while loading submitting your Phone Code. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        authActions.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(authActions.apiFetching(false)),
    ]);
  }
}

function* onResendPhoneCode() {
  tracer.info(
    "User requested to resend phone code.",
    tracer.ids.domain.SAGAS.auth,
  );
  yield all([
    put(authActions.apiSuccess(false)),
    put(authActions.apiFetching(true)),
    put(
      authActions.apiError({
        message: "",
        status: 0,
      }),
    ),
  ]);
  const jwt: string = yield select(selectJwt);
  yield put(authActions.authorizeToken(jwt));
  yield put(authActions.apiFetching(false));
  yield delay(5000);
}

export function* onGetMerchantProfile(
  action: ReturnType<typeof authActions.setLoggedIn>,
) {
  if (!action.payload.loggedIn) {
    return;
  }
  try {
    yield all([
      put(authActions.apiFetching(true)),
      put(authActions.apiSuccess(false)),
      put(authActions.apiError({ message: "", status: 0 })),
    ]);
    const res: GetMerchantProfileResType = yield call(fetchGetMerchantProfile);
    if ("error" in res) {
      tracer.warn(
        "User failed to fetch Merchant Profile",
        tracer.ids.domain.SAGAS.auth,
      );
      yield all([
        put(authActions.apiFetching(false)),
        put(authActions.apiSuccess(false)),
        put(authActions.apiError(res.error)),
      ]);
    } else {
      tracer.info(
        "User successfully fetched Merchant Profile",
        tracer.ids.domain.SAGAS.auth,
      );
      tracer.setSessionAttrs({
        userId: res.data.merchantId,
        isLoggedIn: true,
      });
      yield all([
        put(authActions.apiSuccess(true)),
        put(authActions.apiFetching(false)),
        put(authActions.setMerchantProfile(res.data)),
      ]);
    }
  } catch (error) {
    const errMsg =
      "An error occured while fetching the Merchant Profile. Please try again.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        authActions.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(authActions.apiFetching(false)),
      put(authActions.apiSuccess(false)),
    ]);
  }
}
