import { Stack, Typography } from "@mui/material";
import {
  DesktopOutlined,
  MobileOutlined,
  TabletOutlined,
} from "@ant-design/icons";
import { SessionDbType } from "@pd/redux/types/dbTypes";
import { useTheme } from "@mui/material/styles";
import {
  isAndroid,
  isIOS,
  isMobile,
  isTablet,
  isWindowsPhone,
} from "@pd/utils/browser";
import { LaunchOutlined } from "@mui/icons-material";

type Props = {
  session: SessionDbType;
  // eslint-disable-next-line react/no-unused-prop-types
  onDelete: () => void;
};

export default function SessionRow(props: Props) {
  const theme = useTheme();

  const deviceIcon = (userAgent: string) => {
    // NOTE: The order here is important. Tablet identification must come before mobile.
    if (isTablet(userAgent)) {
      return <TabletOutlined style={{ fontSize: "50px" }} />;
    }

    if (isMobile(userAgent)) {
      return <MobileOutlined style={{ fontSize: "50px" }} />;
    }

    return <DesktopOutlined style={{ fontSize: "50px" }} />;
  };

  const deviceLabel = (userAgent: string) => {
    // NOTE: The order here is important. Tablet identification must come before mobile.
    if (isTablet(userAgent)) {
      if (isIOS(userAgent)) {
        return "iPad";
      }
      return "Tablet";
    }
    if (isMobile(userAgent)) {
      // NOTE: You're just being a hipster at this point.
      if (isWindowsPhone(userAgent)) {
        return "Windows phone";
      }
      if (isAndroid(userAgent)) {
        return "Android";
      }
      if (isIOS(userAgent)) {
        return "iPhone";
      }
      return "Mobile";
    }

    return "Browser";
  };

  const formatNameAndEmail = (
    name: string | undefined,
    email: string | undefined,
  ) => {
    if (name && email) {
      return `${name} (${email})`;
    }
    if (email) {
      return email;
    }
    if (name) {
      return name;
    }
    return "---";
  };

  const formatLocation = (ip: string) => {
    if (ip === "::1" || ip === "127.0.0.1") {
      return <Typography>Development</Typography>;
    }
    return (
      <Typography>
        <Typography>
          <a
            href={`https://ipinfo.io/ips/${ip}`}
            target="_blank"
            rel="noreferrer"
          >
            {ip} <LaunchOutlined style={{ fontSize: "16px" }} />
          </a>
        </Typography>
      </Typography>
    );
  };

  const formatDate = (date: string) => new Date(date).toLocaleDateString();

  const getBackgroundColor = (session: SessionDbType) => {
    if (session.isCurrent) {
      return "#F5F5F5";
    }
    return theme.palette.background.default;
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="500px"
      style={{
        padding: "15px",
        backgroundColor: getBackgroundColor(props.session),
      }}
    >
      <Stack direction="row" spacing={3}>
        <Stack
          direction="column"
          padding="15px"
          alignItems="center"
          spacing={1}
        >
          {deviceIcon(props.session.lastUserAgent)}
          <Typography>{deviceLabel(props.session.lastUserAgent)}</Typography>
        </Stack>

        <Stack direction="column" padding="15px">
          <Typography>
            {formatNameAndEmail(props.session.name, props.session.email)}
          </Typography>
          {formatLocation(props.session.lastIp)}
          <Typography>{formatDate(props.session.createdAt)}</Typography>
        </Stack>
      </Stack>
      {/* NOTE: Not deleting individual sessions for now. Still would need to wire up to API call. */}
      {/* <ButtonBase
        onClick={(e) => {
          e.stopPropagation();
          props.onDelete();
        }}
        sx={{
          padding: "5px",
          "&:hover": {
            cursor: "pointer",
            scale: "1.1",
            transition: "all 0.3s ease",
          },
        }}
      >
        <DeleteOutlined
          style={{ fontSize: "24px", color: theme.palette.error.main }}
        />
      </ButtonBase> */}
    </Stack>
  );
}
