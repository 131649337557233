import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { SessionDbType } from "@pd/redux/types/dbTypes";

export type AllSessionsResType = { data: SessionDbType[] } | ErrorResType;
export const fetchAllSessions = async (): Promise<AllSessionsResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/dashboard/sessions`,
    {
      method: "GET",
      credentials: "include",
    },
  );
  return response;
};

export type DeleteAllSessionsResType = object | ErrorResType;
export const deleteAllSessions =
  async (): Promise<DeleteAllSessionsResType> => {
    const response = await safeFetch(
      `${endpoints.stitchBackend}/dashboard/sessions`,
      {
        method: "DELETE",
        credentials: "include",
      },
    );
    return response;
  };
