import { createAction } from "@reduxjs/toolkit";
import { SessionDbType } from "@pd/redux/types/dbTypes";
import { ErrorPayload } from "@pd/api/utils/safeFetch";

const allSessionsActions = {
  apiFetching: createAction(
    "dashboard/sessions/all/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/sessions/all/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/sessions/all/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  fetchAllSessions: createAction("dashboard/sessions/all/fetchAllSessions"),
  setAllSessions: createAction(
    "dashboard/sessions/all/setAllSessions",
    (sessions: SessionDbType[]) => ({
      payload: { sessions },
    }),
  ),
  deleteAllSessions: createAction("dashboard/sessions/all/deleteAllSessions"),
};

export default allSessionsActions;
