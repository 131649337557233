import safeFetch, { ErrorResType } from "@pd/api/utils/safeFetch";
import { endpoints } from "@pd/config/envSetup";
import { getStitchApp } from "@pd/utils/appCheck";

export type AuthorizeResType =
  | { data: string }
  | { data: { next: string } }
  | ErrorResType;
export const fetchAuthorizeToken = async (
  token: string,
): Promise<AuthorizeResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/auth/${getStitchApp()}/authorize/${token}`,
    {
      method: "PUT",
      credentials: "include",
    },
  );
  return response;
};

export type SubmitPhoneCodeResType = { data: { next: string } } | ErrorResType;
export const fetchSubmitPhoneCode = async (
  phoneCode: string,
  token: string,
  trustThisDevice: boolean,
): Promise<SubmitPhoneCodeResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/auth/${getStitchApp()}/authorize/${token}`,
    {
      method: "POST",
      body: JSON.stringify({
        otp: phoneCode,
        trust_this_device: trustThisDevice,
      }),
      credentials: "include",
    },
  );

  if (typeof response === "string") {
    return { data: { next: response } };
  }
  return response;
};

export type SendMagicLinkResType = { data: string } | ErrorResType;
export const fetchSendMagicLink = async (
  email: string,
): Promise<SendMagicLinkResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/auth/login/${getStitchApp()}`,
    {
      method: "POST",
      body: JSON.stringify({ email }),
    },
  );

  if (typeof response === "string") {
    return { data: response };
  }
  return response;
};

export type LogoutResType = object | ErrorResType;
export const fetchLogout = async (): Promise<LogoutResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/${getStitchApp()}/logout`,
    {
      method: "POST",
      credentials: "include",
    },
  );

  return response;
};

export type RefreshSessionResType = object | ErrorResType;
export const fetchRefreshSession = async (): Promise<RefreshSessionResType> => {
  const response = await safeFetch(
    `${endpoints.stitchBackend}/${getStitchApp()}/refresh`,
    {
      method: "POST",
      credentials: "include",
    },
  );

  return response;
};
