import { all, call, put, takeLeading } from "redux-saga/effects";
import { MANUAL_ERROR_CODE } from "@pd/utils/constants";

import {
  fetchSubmitCreditAppAsReq,
  DashSubmitCreditAppAsReqResType,
  DashGetCreditLimitResType,
  fetchGetCreditLimit,
} from "@pd/api/dashboard/credit";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";
import tracer from "@pd/tracing";

export default function* crudBuyerSagas() {
  yield all([
    takeLeading(da.credit.app.submit.toString(), onSubmitCreditAppAsRequestor),
    takeLeading(
      da.credit.limit.fetchCreditLimitInfo.toString(),
      onFetchCreditLimitInfo,
    ),
  ]);
}

function* onSubmitCreditAppAsRequestor(
  action: ReturnType<typeof da.credit.app.submit>,
) {
  try {
    yield all([
      put(da.credit.app.apiFetching(true)),
      put(da.credit.app.apiSuccess(false)),
      put(da.credit.app.apiError({ message: "", status: 0 })),
    ]);
    const res: DashSubmitCreditAppAsReqResType = yield call(
      fetchSubmitCreditAppAsReq,
      action.payload.data,
      action.payload.sendEmail,
      action.payload.buyerId,
    );
    if ("error" in res) {
      tracer.warn(
        "Unable to submit credit application",
        tracer.ids.domain.SAGAS.credit,
        { error: res.error.message },
      );
      yield all([
        put(da.credit.app.apiError(res.error)),
        put(da.credit.app.apiSuccess(false)),
        put(da.credit.app.apiFetching(false)),
      ]);
    } else {
      tracer.info(
        "Credit application submitted successfully",
        tracer.ids.domain.SAGAS.credit,
        { creditAppId: res.data.id },
      );
      yield all([
        put(da.credit.app.setApiData(res.data)),
        put(da.credit.app.apiFetching(false)),
        put(da.credit.app.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while creating the buyer. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.credit.app.apiError({ message: errMsg, status: MANUAL_ERROR_CODE }),
      ),
      put(da.credit.app.apiFetching(false)),
      put(da.credit.app.apiSuccess(false)),
    ]);
  }
}

function* onFetchCreditLimitInfo() {
  try {
    yield all([
      put(da.credit.limit.apiFetching(true)),
      put(da.credit.limit.apiSuccess(false)),
      put(da.credit.limit.apiError({ message: "", status: 0 })),
    ]);
    const res: DashGetCreditLimitResType = yield call(fetchGetCreditLimit);
    if ("error" in res) {
      tracer.warn(
        "Unable to get credit limit",
        tracer.ids.domain.SAGAS.credit,
        { error: res.error.message },
      );
      yield all([
        put(da.credit.limit.apiError(res.error)),
        put(da.credit.limit.apiSuccess(false)),
        put(da.credit.limit.apiFetching(false)),
      ]);
    } else {
      yield all([
        put(
          da.credit.limit.setCreditLimitInfo(
            res.data.creditLimit,
            res.data.consumedCredit,
          ),
        ),
        put(da.credit.limit.apiFetching(false)),
        put(da.credit.limit.apiSuccess(true)),
      ]);
    }
  } catch (error: unknown) {
    const errMsg =
      "An error occurred while fetching the credit limit info. Please try again later.";
    if (error instanceof Error) {
      console.error(error.message);
    }
    yield all([
      put(
        da.credit.limit.apiError({
          message: errMsg,
          status: MANUAL_ERROR_CODE,
        }),
      ),
      put(da.credit.limit.apiFetching(false)),
      put(da.credit.limit.apiSuccess(false)),
    ]);
  }
}
