import { createAsyncSlice } from "@pd/redux/types";
import { createReducer } from "@reduxjs/toolkit";
import { AllSessionsStateType } from "@pd/layouts/MktplaceDashboard/types/sessions";
import da from "@pd/layouts/MktplaceDashboard/redux/actions";

export const initAllSessionsState = createAsyncSlice({
  sessions: [],
});

export default function createAllSessionsReducer() {
  return createReducer<AllSessionsStateType>(
    initAllSessionsState,
    (builder) => {
      builder
        .addCase(da.sessions.all.apiFetching, onApiFetching)
        .addCase(da.sessions.all.apiError, onApiError)
        .addCase(da.sessions.all.apiSuccess, onApiSuccess)
        .addCase(da.sessions.all.setAllSessions, onSetAllSessions);
    },
  );
}

export function onApiFetching(
  state: AllSessionsStateType,
  action: ReturnType<typeof da.sessions.all.apiFetching>,
) {
  state.fetching = action.payload.fetching;
}

export function onApiError(
  state: AllSessionsStateType,
  action: ReturnType<typeof da.sessions.all.apiError>,
) {
  state.error = action.payload.error;
}

export function onApiSuccess(
  state: AllSessionsStateType,
  action: ReturnType<typeof da.sessions.all.apiSuccess>,
) {
  state.success = action.payload.success;
}

export function onSetAllSessions(
  state: AllSessionsStateType,
  action: ReturnType<typeof da.sessions.all.setAllSessions>,
) {
  state.data.sessions = action.payload.sessions;
}
