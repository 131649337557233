import { useNavigate, useParams } from "react-router";
import { Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import authActions from "@pd/redux/actions/auth";
import StitchLoading from "@pd/components/StitchLoading";
import {
  selectAuthApiFetching,
  selectAuthApiError,
  selectLoggedIn,
  selectAuthApiSuccess,
} from "@pd/redux/selectors/auth";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import LocalErrorMsg from "@pd/components/LocalErrorMsg";
import { useEffect } from "react";

export default function Authorize() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loggedIn = useAppSelector(selectLoggedIn);
  const apiError = useAppSelector(selectAuthApiError);
  const apiFetching = useAppSelector(selectAuthApiFetching);
  const apiSuccess = useAppSelector(selectAuthApiSuccess);
  const params = useParams<{ token: string }>();

  const onContinueClick = () => {
    dispatch(authActions.authorizeToken(params.token || ""));
  };

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  }, [loggedIn]);

  const getContent = (): React.JSX.Element => {
    if (!params.token || loggedIn) {
      return <StitchLoading />;
    }
    return (
      <Stack alignItems="center" gap={3}>
        <Typography variant="h2" textAlign="center">
          Log in to Stitch
        </Typography>
        <Typography variant="body1" textAlign="center">
          Sign in to view your Stitch Dashboard.
        </Typography>
        <LocalErrorMsg error={apiError} />
        <StitchAsyncButton
          buttonText="Continue"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => onContinueClick()}
          success={apiSuccess}
          loading={apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    );
  };

  return (
    <Stack
      alignItems="flex-start"
      sx={{
        maxWidth: "488px",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {getContent()}
    </Stack>
  );
}
