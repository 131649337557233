import { ErrorPayload } from "@pd/api/utils/safeFetch";
import { CreditAppRequestorFormType } from "@pd/layouts/MktplaceDashboard/types/credit";
import {
  AddressDbType,
  BuyerDetailDbType,
  CreditAppSectionDbType,
} from "@pd/redux/types/dbTypes";
import { createAction } from "@reduxjs/toolkit";

export const creditAppActions = {
  apiFetching: createAction(
    "dashboard/credit/app/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/credit/app/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/credit/app/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  submit: createAction(
    "dashboard/credit/app/submit",
    (
      data: CreditAppRequestorFormType,
      sendEmail: boolean,
      buyerId: string,
    ) => ({
      payload: { data, sendEmail, buyerId },
    }),
  ),
  setUiData: createAction(
    "dashboard/credit/app/setUiData",
    (key: string, value: string | AddressDbType) => ({
      payload: { key, value },
    }),
  ),
  setApiData: createAction(
    "dashboard/credit/app/setApiAppInfo",
    (data: CreditAppSectionDbType) => ({
      payload: { data },
    }),
  ),
  clearState: createAction("dashboard/credit/app/clearState"),
  hydrateUiApp: createAction(
    "dashboard/credit/app/hydreateUiApp",
    (dbBuyer: BuyerDetailDbType) => ({
      payload: { dbBuyer },
    }),
  ),
};

export const creditLimitActions = {
  apiFetching: createAction(
    "dashboard/credit/limit/apiFetching",
    (fetching: boolean) => ({
      payload: { fetching },
    }),
  ),
  apiError: createAction(
    "dashboard/credit/limit/apiError",
    (error: ErrorPayload) => ({
      payload: { error },
    }),
  ),
  apiSuccess: createAction(
    "dashboard/credit/limit/apiSuccess",
    (success: boolean) => ({
      payload: { success },
    }),
  ),
  fetchCreditLimitInfo: createAction(
    "dashboard/credit/limit/fetchCreditLimitInfo",
  ),
  setCreditLimitInfo: createAction(
    "dashboard/credit/limit/setCreditLimitInfo",
    (creditLimit: number, consumedCredit: number) => ({
      payload: { creditLimit, consumedCredit },
    }),
  ),
};
