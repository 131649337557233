import { useEffect, useState, useRef, ChangeEvent } from "react";
import { useNavigate } from "react-router";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import authActions from "@pd/redux/actions/auth";
import { useAppDispatch, useAppSelector } from "@pd/redux/store";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import GenericRetryButton from "@pd/components/GenericRetryError";
import {
  selectAuthApiSuccess,
  selectAuthApiFetching,
  selectAuthApiError,
  selectJwt,
} from "@pd/redux/selectors/auth";
import LocalErrorMsg from "@pd/components/LocalErrorMsg";
import "./styles.css";

export default function PhoneCodeForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const apiSuccess = useAppSelector(selectAuthApiSuccess);
  const apiFetching = useAppSelector(selectAuthApiFetching);
  const apiError = useAppSelector(selectAuthApiError);
  const jwt = useAppSelector(selectJwt);

  const [resendFetching, setResendFetching] = useState(false);
  const [submitFetching, setSubmitFetching] = useState(false);
  const [showResentMsg, setShowResentMsg] = useState(false);
  const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);
  const [phoneCode, setPhoneCode] = useState("");
  const [trustThisDevice, setTrustThisDevice] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const onTrustThisDeviceChange = (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setTrustThisDevice(checked);
  };

  const handleOnChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;
    if (
      "inputType" in e.nativeEvent &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      return;
    }
    if (index === 5 && phoneCode.length === 6) {
      return;
    }
    if (!value || /^\D+$/.test(value)) {
      const nextValue = value.replace(/\D/g, "");
      setPhoneCode((prev) => prev + nextValue);
    } else setPhoneCode((prev) => prev + value);

    if (value.length === 1 && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }

    if (value.length > 1) {
      setPhoneCode(value);
      value.split("").forEach((_, i) => {
        if (i < 5) {
          inputRefs.current[index + i + 1]?.focus();
        }
      });
    }
  };

  const handleSubmit = () => {
    if (!(phoneCode.length === 6)) {
      return;
    }
    setSubmitFetching(true);
    setShowResentMsg(false);
    dispatch(authActions.submitPhoneCode(phoneCode, trustThisDevice));
  };

  const handleOnKeyDown = (
    _index: number,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const { key } = e;
    const codeInputs: NodeListOf<HTMLInputElement> =
      document.querySelectorAll(".phoneNumber-input");
    if (key === "Backspace") {
      const { value } = codeInputs[_index];

      if (value) {
        setPhoneCode((prev) => {
          const nextValue = prev.slice(0, -1);
          return nextValue;
        });
      } else {
        const prevIndex = _index - 1;
        const prevValue = codeInputs[prevIndex].value;
        if (prevValue) {
          setPhoneCode((prev) => {
            const nextValue = prev.slice(0, -1);
            return nextValue;
          });
          codeInputs[prevIndex].focus();
        }
      }
    } else if (key === "Enter") {
      handleSubmit();
    }
  };

  const handleResendCode = () => {
    setResendFetching(true);
    dispatch(authActions.resendPhoneCode());
  };

  useEffect(() => {
    if (!apiFetching && resendFetching && apiError.message) {
      setResendFetching(false);
    } else if (!apiFetching && resendFetching) {
      setResendFetching(false);
      setShowResentMsg(true);
    } else if (!apiFetching && submitFetching && apiError.message) {
      setSubmitFetching(false);
      setShowSubmitSuccess(false);
      setPhoneCode("");
    } else if (!apiFetching && submitFetching) {
      setSubmitFetching(false);
      setShowSubmitSuccess(true);
    }
  }, [apiFetching, submitFetching, resendFetching, apiError.message]);

  useEffect(() => {
    if (!jwt) {
      navigate("/auth/login/email", { replace: true });
    }
    return () => {
      dispatch(authActions.apiSuccess(false));
      dispatch(authActions.apiFetching(false));
      dispatch(
        authActions.apiError({
          status: 0,
          message: "",
        }),
      );
    };
  }, []);

  useEffect(() => {
    if (apiSuccess) {
      navigate("/");
    }
  }, [apiSuccess]);

  useEffect(() => {
    setTimeout(() => {
      inputRefs.current[0]?.focus();
    }, 400);
  }, []);

  return (
    <Stack
      sx={{ height: "100%", maxWidth: "488px" }}
      alignItems="center"
      justifyContent="center"
      gap={5}
    >
      <Stack
        data-testid="header-title"
        alignItems="center"
        gap={1}
        sx={{ mb: 4 }}
      >
        <Typography variant="h2" textAlign="center">
          Phone 2FA
        </Typography>
        <Typography variant="body1" textAlign="center">
          Enter the code sent to your phone number to complete your login.
        </Typography>
      </Stack>
      <Stack
        gap={1}
        data-testid="phone-input"
        direction="row"
        justifyContent="space-evenly"
      >
        {[1, 2, 3, 4, 5, 6].map((_, index) => (
          <input
            type="number"
            key={_}
            disabled={apiFetching}
            inputMode="numeric"
            pattern="[0-9]{1}"
            name={`phoneCode.${index}`}
            value={!phoneCode[index] ? "" : phoneCode[index]}
            ref={(el) => {
              inputRefs.current[index] = el;
            }}
            onChange={(e) => handleOnChange(index, e)}
            onKeyDown={(e) => handleOnKeyDown(index, e)}
            className="phoneNumber-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.main}`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              width: "40px",
              fontSize: "24px",
              lineHeight: "16px",
              fontFamily: "CircularRegular",
              textAlign: "center",
            }}
          />
        ))}
      </Stack>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={trustThisDevice}
              disabled={apiFetching}
              onChange={onTrustThisDeviceChange}
            />
          }
          label="Trust this device"
        />
      </FormGroup>
      <Stack sx={{ width: "100%" }} gap={2}>
        <Typography
          sx={{
            display: showResentMsg ? "block" : "none",
          }}
          variant="subtitle1"
          color={theme.palette.success.darker}
          align="center"
        >
          {"Code resent! Check your messages."}
        </Typography>
        <LocalErrorMsg error={apiError} />
        <GenericRetryButton error={apiError} onClick={handleSubmit} />
        <StitchAsyncButton
          buttonText="Resend code"
          variant="outlined"
          color="primary"
          logoColor="black"
          onClick={() => handleResendCode()}
          success={showResentMsg}
          loading={resendFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
        <StitchAsyncButton
          buttonText="Submit"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={() => handleSubmit()}
          success={showSubmitSuccess}
          loading={submitFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </Stack>
  );
}
