import { useEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  redirect,
  Params,
} from "react-router-dom";
import { isTestEnv } from "@pd/utils/envCheck";
import Authorize from "@pd/pages/Auth/Authorize";
import AuthLogin from "@pd/pages/Auth";
import AuthEmailLogin from "@pd/pages/Auth/EmailLogin";
import AuthPhoneLogin from "@pd/pages/Auth/PhoneLogin";
import AuthLinkExpired from "@pd/pages/Auth/LinkExpired";
import routes404 from "@pd/pages/Page404/routes";
import routes500 from "@pd/pages/Page500/routes";
import { getStitchApp } from "@pd/utils/appCheck";
import { useAppSelector } from "@pd/redux/store";
import {
  selectLoggedIn,
  selectRedirectDestination,
} from "@pd/redux/selectors/auth";
import authActions from "@pd/redux/actions/auth";
import ErrorBoundary from "@pd/components/ErrorBoundary";
import { put } from "redux-saga/effects";

async function loadChildren() {
  let children = [
    ...routes404,
    ...routes500,
    {
      element: <Outlet />,
      children: [
        {
          element: <AuthLogin />,
          errorElement: <ErrorBoundary fallback />,
          children: [
            {
              path: "/launch/:token",
              loader: async ({
                params,
              }: {
                params: Params<string>;
              }): Promise<string> => {
                if (!params.token) {
                  redirect("/404");
                  return "";
                }
                return params.token;
              },
              element: <Authorize />,
              errorElement: <ErrorBoundary fallback />,
            },
          ],
        },
      ],
    },
    {
      path: "/auth",
      element: <Outlet />,
      children: [
        {
          path: "login",
          element: <AuthLogin />,
          errorElement: <ErrorBoundary fallback />,
          children: [
            {
              path: "email",
              element: <AuthEmailLogin />,
              errorElement: <ErrorBoundary fallback />,
            },
            {
              path: "phone",
              element: <AuthPhoneLogin />,
              errorElement: <ErrorBoundary fallback />,
            },
          ],
        },
        {
          path: "link-expired",
          element: <AuthLinkExpired />,
          errorElement: <ErrorBoundary fallback />,
        },
      ],
    },
  ];
  if (process.env.STITCH_APP === "dashboard") {
    const dashboardRoutes = await import(
      "@pd/layouts/MktplaceDashboard/routes"
    );
    children = children.concat(dashboardRoutes.default);
  } else if (process.env.STITCH_APP === "portal") {
    const buyerRoutes = await import("@pd/layouts/BuyerPortal/routes");
    const creditRoutes = await import("@pd/layouts/BuyerCredit/routes");
    children = children.concat(buyerRoutes.default);
    children = children.concat(creditRoutes.default);
  }
  if (isTestEnv()) {
    const devDashboard = await import("@pd/pages/DebuggerDash");
    const DevDashboard = devDashboard.default;
    children.push({
      path: "/debugger",
      element: <DevDashboard />,
    });
  }
  return children;
}

const MainRoutes = async () => ({
  path: "/",
  element: <MainRoute />,
  errorElement: routes404[0].element,
  children: await loadChildren(),
});
export default MainRoutes;

function MainRoute() {
  const loggedIn: boolean = useAppSelector(selectLoggedIn);
  const location = useLocation();
  const navigate = useNavigate();
  const redirectDestination = useAppSelector(selectRedirectDestination);

  const { pathname } = location;

  useEffect(() => {
    // If sent to /, regardless of how, should redirect to somewhere actionable, such as
    // main orders list in dashboard or login page.
    if (pathname === "/") {
      if (loggedIn) {
        const stitchApp = getStitchApp();
        if (stitchApp === "dashboard") {
          // console.log(
          //   "redirecting in MainRoutes from / to getStitchApp()/orders",
          // );
          navigate(`/${getStitchApp()}/orders`, { replace: true });
        } else {
          // console.log("redirecting in MainRoutes from / to getStitchApp()");
          navigate(`/${getStitchApp()}`, { replace: true });
        }
      } else {
        // console.log("redirecting in MainRoutes from / to /auth/login/email");
        navigate("/auth/login/email", { replace: true });
      }
    }
  }, [location]);

  useEffect(() => {
    if (redirectDestination) {
      if (redirectDestination === "/") {
        if (loggedIn) {
          const stitchApp = getStitchApp();
          if (stitchApp === "dashboard") {
            // console.log("swapping out / for getStitchApp()/orders");
            navigate(`/${getStitchApp()}/orders`, { replace: true });
          } else {
            // console.log("swapping out / for getStitchApp()");
            navigate(`/${getStitchApp()}`, { replace: true });
          }
        } else {
          // console.log("swapping out / for /auth/login/email");
          navigate("/auth/login/email", { replace: true });
        }
      } else if (redirectDestination === "/login") {
        // console.log("swapping out /login for /auth/login/email");
        navigate("/auth/login/email", { replace: true });
      } else if (
        redirectDestination.toLowerCase().startsWith("http://") ||
        redirectDestination.toLowerCase().startsWith("https://")
      ) {
        // console.log(`redirecting to absolute path ${redirectDestination} via MainRoutes`);
        window.location.assign(redirectDestination);
      } else {
        // console.log(`redirecting to relative path ${redirectDestination} via MainRoutes`);
        navigate(redirectDestination, { replace: true });
      }
      put(authActions.clearRedirectDestination());
    }
  }, [redirectDestination, loggedIn]);
  return <Outlet />;
}
